import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SpinnerLoading from "../../components/common/spinnerLoading"

class Articolo extends Component {
  state = {
    slug: "",
    article: { attributes: { title: "" } },
    loading: true,
  }

  componentDidMount = async () => {
    const { article } = this.props.pageContext
    this.setState({ article, loading: false })
  }

  render() {
    const { loading, article } = this.state
    return (
      <Layout>
        <SEO
          title={article.attributes.title}
          description={article.attributes.title}
        />

        <div className="container">
          <div className={"row"}>
            <div className={"col-12"}>
              {loading && (
                <div className="my-5 py-5">
                  <SpinnerLoading />
                </div>
              )}
              {!loading && (
                <div>
                  <h1 className={"page-title"}>{article.attributes.title}</h1>

                  <img
                    src={article.relationships.media.data[0].attributes.url}
                    className={"news-image"}
                    alt=""
                  />

                  <div
                    className={"news-body"}
                    dangerouslySetInnerHTML={{
                      __html: article.attributes.body,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Articolo
